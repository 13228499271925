<template>
  <div class="box" :class="{'error': $props.error}">
    <label class="label" :for="'select_' + $props.name">Guests</label>
    <svg class="ico">
      <use xlink:href="#icon-guests"/>
    </svg>
    <div class="select">
      <select @change="change($event)" :name="search" :id="'select_' + $props.name" :value="$attrs.data">
        <option value="" disabled selected>
          {{ placeholder }}
        </option>
        <option v-for="(opts, index) in options" :key="'select ' + index" :value="opts">{{ opts + ' ' + name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      name: String,
      icon: String,
      error: Boolean,
      search: String,
      options: {
          type: Array,
          required: true
      },
      classN: String,
      placeholder: String,
      required: Boolean
  },
  methods: {
    change: function(event) {
      this.$emit('track', this.$props.name + "_filter_change");
      this.$emit('update:data', event.target.value);
    }
  }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.box {
  label {
    top: 10px;
    left: 42px;
    z-index: 1;
    font-weight: 600;
    font-size: 0.75rem;
    position: absolute;
    pointer-events: none;
  }

  svg {
    top: 15px;
    left: 1rem;
    z-index: 1;
    width: 18px;
    height: 18px;
    position: absolute;
    pointer-events: none;
  }

  .select {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border-radius: .3rem;
    border: 0.125rem solid #d8d8d8;
    background-image: none !important;

    select {
      width: 130%;
      height: 3rem;
      border: none;
      cursor: pointer;
      font-weight: 700;
      box-shadow: none;
      appearance: none;
      line-height: 3rem;
      font-size: .875rem;
      background-color: #FAFAFA;
      box-sizing: border-box;
      text-transform: uppercase;
      padding: 18px 0 0 42px !important;

      .isIE & {
        width: 140%;
      }
    }

    select:focus {
      outline: none;
    }
  }
}

@media #{$desktop} {
  .select {
    width: 12rem;
  }
}
</style>