import Error from '../error.vue'

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('#error');
    const data = el.dataset;
    const app = new Vue({
        el,
        render(h){
            return h(Error, {
            props: {
                status: data.status,
                config: JSON.parse(data.config),
                wknd: data.wknd,
                title: data.title.split(':'),
                region: data.region,
            }
        })}
    })
})